import { makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) => {
  return {
    container: {
      [theme.breakpoints.down("sm")]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    buttonWaze: {
      minWidth: 40,
    },
    buttonWazeImage: {
      height: 30,
    },
    emptyText: {
      marginLeft: 20,
    },
    listItemText: {
      marginRight: theme.spacing(3),
    },
    lisItemTextOverflow: {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
    listItem: {
      paddingRight: 90,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    listItemError: {
      backgroundColor: theme.palette.error.light,
    },
    listHeader: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      display: "flex",
      justifyContent: "flex-end",
      flexWrap: "wrap",
    },
    listHeaderText: {
      fontWeight: "bold",
    },
    menuDaysPaper: {
      maxWidth: "100%",
    },
    menuDays: {
      width: 300,
    },
    menuDaysInput: {
      width: 45,
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  };
});
