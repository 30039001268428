import {
  formatDistanceToNow,
  differenceInCalendarDays,
  parseISO,
  format,
} from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";

export const getDateToNow = (dateIn: Date): string => {
  const enUsDate = zonedTimeToUtc(new Date(dateIn), "America/New_York");
  const formattedDate = formatDistanceToNow(enUsDate, {
    addSuffix: true,
  });
  return formattedDate;
};

export const moreThanNDays = (dateIn: Date, days: number): boolean => {
  const enUsDate = zonedTimeToUtc(new Date(dateIn), "America/New_York");
  const today = zonedTimeToUtc(new Date(), "America/New_York");
  const diff = differenceInCalendarDays(today, enUsDate);
  return diff > days;
};

export const generateYearArray = (): string[] => {
  // const currentYear = new Date().getFullYear();
  const years = ["2022", "2023", "2024"];

  return years;
};

export const getDateFromParam = (param: string | null) => {
  return param ? parseISO(param) : null;
};

export const formatDate = (date: Date) => {
  return format(date, "yyyy-MM-dd");
};
