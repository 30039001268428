import React from "react";
import {
  ListItemText,
  ListItem,
  ListItemSecondaryAction,
  IconButton,
  Button,
} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { useHistory } from "react-router-dom";
// Styles
import { useStyles } from "./HomePage.styles";
// Interfaces
import { Tree } from "api/types";
// Assets
import WazeImg from "../../detail/waze.png";
// Utils
import { moreThanNDays, getDateToNow } from "utils/date";

interface Props {
  tree: Tree;
  onStartWatering(id: string): void;
  daysDelayed: number;
}

const HomePageTreeListItem = React.forwardRef<any, Props>((props, ref) => {
  const history = useHistory();
  const classes = useStyles();
  const { tree, onStartWatering, daysDelayed } = props;

  const onGoRoute = (id: string) => {
    history.push(`/detail/${id}`);
  };

  const onRouteWaze = (latitude: number, longitude: number) => {
    window.location.href = `https://www.waze.com/ul?ll=${latitude},${longitude}&navigate=yes&zoom=17`;
  };

  const lastWatering = tree.lastWateringAt
    ? `${getDateToNow(tree.lastWateringAt)}`
    : "No data";

  const secondary = `${
    tree.timeDistance ? tree.timeDistance + "min " : ""
  }${tree.distance.toFixed(2)}mile(s) Last Watering: ${lastWatering}`;

  const hasMoreThanNDays =
    tree.lastWateringAt && moreThanNDays(tree.lastWateringAt, daysDelayed);

  const classNames = `${classes.listItem} ${
    hasMoreThanNDays ? classes.listItemError : ""
  }`;

  return (
    <ListItem
      ref={ref}
      key={tree.id}
      button
      divider
      className={classNames}
      onClick={() => onGoRoute(tree.id)}
    >
      <ListItemText
        classes={{
          primary: classes.lisItemTextOverflow,
          secondary: classes.lisItemTextOverflow,
          root: classes.listItemText,
        }}
        primary={tree.address ? tree.address : ""}
        secondary={secondary}
      />
      <ListItemSecondaryAction>
        <Button
          className={classes.buttonWaze}
          onClick={() => onRouteWaze(tree.latitude, tree.longitude)}
        >
          <img
            src={WazeImg}
            className={classes.buttonWazeImage}
            alt="wazeicon"
          />
        </Button>
        <IconButton onClick={() => onStartWatering(tree.id)}>
          <CheckCircleIcon fontSize="large" color="primary" />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
});

HomePageTreeListItem.displayName = "HomePageTreeListItem";

export default HomePageTreeListItem;
