import ApolloClient from "apollo-boost";
import { InMemoryCache } from "apollo-cache-inmemory";

import StorageService from "services/Storage";

type Error =
  | {
      message: string;
      status: number;
    }
  | Record<string, unknown>;

const client = new ApolloClient({
  uri: process.env.REACT_APP_API_URI,
  cache: new InMemoryCache({
    dataIdFromObject: (obj) => obj.id,
  }),
  request: (operation) => {
    const authToken = StorageService.getUserToken();
    operation.setContext({
      headers: {
        authorization: authToken ? `Bearer ${authToken}` : "",
      },
    });
  },
  onError: (error) => {
    const { graphQLErrors } = error;
    const err = ((graphQLErrors &&
      graphQLErrors?.length > 0 &&
      graphQLErrors[0]) ||
      {}) as Error;
    if (err && err.status === 401) {
      console.log("Removing");
      StorageService.removeUserToken();
    }
  },
});

export default client;
