/* eslint-disable react/no-unescaped-entities */
import React, { useCallback, useMemo, useRef } from "react";
import {
  Typography,
  CircularProgress,
  makeStyles,
  Box,
  Container,
  List,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { useQuery } from "@apollo/react-hooks";
import { USER_WATERINGS } from "api/queries";
import { UserWaterings } from "api/types";
import { uniqBy } from "lodash";
import WateringsListItem from "./WateringsListItem";
import { Eco } from "@material-ui/icons";

type ReportsContentProps = {
  queryDates: { start: string; end: string } | null;
  userId: string;
};

const useStyles = makeStyles((theme) => ({
  alert: {
    marginTop: theme.spacing(2),
  },
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(4),
    textAlign: "center",
  },
  list: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: theme.spacing(2),
  },
  titleIcon: {
    marginRight: theme.spacing(1),
    fontSize: "2rem",
  },
  titleText: {
    fontWeight: "bold",
  },
}));

const WateringsContent = ({ queryDates, userId }: ReportsContentProps) => {
  const classes = useStyles();
  const observerRef = useRef<IntersectionObserver | null>(null);

  const variables = {
    dateStart: queryDates?.start || "",
    dateEnd: queryDates?.end || "",
    userId,
    offset: 0,
  };

  const { data, loading, error, fetchMore } = useQuery<{
    getUserWaterings: UserWaterings;
  }>(USER_WATERINGS, {
    variables,
    skip: !queryDates,
  });

  const waterings = useMemo(() => data?.getUserWaterings.waterings ?? [], [
    data,
  ]);

  const lastTreeElRef = useCallback(
    (node: Element | null) => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
      observerRef.current = new IntersectionObserver(async (entries) => {
        if (entries[0].isIntersecting && waterings.length) {
          try {
            await fetchMore({
              variables: { offset: waterings.length },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (
                  !fetchMoreResult ||
                  (fetchMoreResult?.getUserWaterings &&
                    !fetchMoreResult?.getUserWaterings.waterings.length)
                ) {
                  return prev;
                }
                const uniqWaterings = uniqBy(
                  [
                    ...prev.getUserWaterings.waterings,
                    ...fetchMoreResult.getUserWaterings.waterings,
                  ],
                  "id"
                );
                return {
                  getUserWaterings: {
                    ...prev.getUserWaterings,
                    waterings: uniqWaterings,
                  },
                };
              },
            });
          } catch (error) {
            console.log("error: ", error);
          }
        }
      });
      if (node) {
        observerRef.current.observe(node);
      }
    },
    [fetchMore, waterings]
  );

  if (loading)
    return (
      <Box display={"flex"} justifyContent={"center"} mt={2}>
        <CircularProgress />
      </Box>
    );
  if (error)
    return (
      <Alert severity="error" className={classes.alert}>
        Error: {error.message}
      </Alert>
    );
  if (waterings.length === 0)
    return (
      <Alert severity="warning" className={classes.alert}>
        No data available for the selected date range.
      </Alert>
    );

  const userName = data?.getUserWaterings.user.name;

  const sortedWaterings = [...waterings].sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  );

  return (
    <Container className={classes.root}>
      <div className={classes.titleContainer}>
        <Eco className={classes.titleIcon} />
        <Typography variant="h4" className={classes.titleText}>
          {userName}'s Waterings
        </Typography>
      </div>
      <List className={classes.list}>
        {sortedWaterings.map((watering) => (
          <WateringsListItem
            ref={lastTreeElRef}
            key={watering.id}
            watering={watering}
          />
        ))}
      </List>
    </Container>
  );
};

export { WateringsContent };
