import React, { useMemo } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Grid,
  Typography,
  Link,
  CircularProgress,
  makeStyles,
  Box,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { useQuery } from "@apollo/react-hooks";
import { USERS_WATERINGS } from "api/queries";
import { UserWatering } from "api/types";

type ReportsContentProps = {
  queryDates: { start: string; end: string } | null;
};

const useStyles = makeStyles((theme) => ({
  alert: {
    marginTop: theme.spacing(2),
  },
}));

const ReportsContent = ({ queryDates }: ReportsContentProps) => {
  const classes = useStyles();

  const { data, loading, error } = useQuery<{ getWaterings: UserWatering[] }>(
    USERS_WATERINGS,
    {
      variables: {
        dateStart: queryDates?.start || "",
        dateEnd: queryDates?.end || "",
      },
      skip: !queryDates,
    }
  );

  const userWaterings = useMemo(() => {
    if (!data?.getWaterings) return [];
    return data.getWaterings.sort((a, b) => {
      if (a.waterings !== b.waterings) {
        return b.waterings - a.waterings; // Sort by waterings in descending order
      } else if (a.waterings === 0 && b.waterings === 0) {
        return a.user.name.localeCompare(b.user.name); // Sort by name if waterings are 0
      } else {
        return 0;
      }
    });
  }, [data]);

  if (!queryDates) {
    return (
      <Alert severity="info" className={classes.alert}>
        Please select a date range and submit to see the data.
      </Alert>
    );
  }

  if (loading)
    return (
      <Box display={"flex"} justifyContent={"center"} mt={2}>
        <CircularProgress />
      </Box>
    );
  if (error)
    return (
      <Alert severity="error" className={classes.alert}>
        Error: {error.message}
      </Alert>
    );
  if (userWaterings.length === 0)
    return (
      <Alert severity="warning" className={classes.alert}>
        No data available for the selected date range.
      </Alert>
    );

  return (
    <div>
      <Typography variant="h6" gutterBottom>
        Users and Waterings
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Typography variant="subtitle1">User Name</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Total Waterings</Typography>
        </Grid>
      </Grid>
      {userWaterings.map(({ user, waterings }) => (
        <Grid key={user.id} container spacing={3}>
          <Grid item xs={6}>
            <Link
              component={RouterLink}
              to={`/reports/${user.id}?start=${queryDates.start}&end=${queryDates.end}`}
            >
              {user.name}
            </Link>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">{waterings}</Typography>
          </Grid>
        </Grid>
      ))}
    </div>
  );
};

export { ReportsContent };
