import gql from "graphql-tag";

export const TREE_STATS = gql`
  query {
    treeStats {
      count
      year
    }
  }
`;

export const USER_WATERINGS = gql`
  query getUserWaterings(
    $userId: String!
    $dateStart: String!
    $dateEnd: String!
    $offset: Int
  ) {
    getUserWaterings(
      userId: $userId
      dateStart: $dateStart
      dateEnd: $dateEnd
      offset: $offset
    ) {
      user {
        id
        name
        username
      }
      waterings {
        id
        createdAt
        tree {
          address
        }
      }
    }
  }
`;

export const USERS_WATERINGS = gql`
  query getWaterings($dateStart: String!, $dateEnd: String!) {
    getWaterings(dateStart: $dateStart, dateEnd: $dateEnd) {
      user {
        id
        name
        username
      }
      waterings
    }
  }
`;

export const TREES = gql`
  query Trees(
    $latitude: Float!
    $longitude: Float!
    $offset: Int
    $limit: Int
    $filterBy: TreeFilter
    $orderBy: TreeOrder
    $daysDelayed: Int
    $year: String
    $season: TreeSeason
  ) {
    trees(
      latitude: $latitude
      longitude: $longitude
      offset: $offset
      limit: $limit
      filterBy: $filterBy
      orderBy: $orderBy
      daysDelayed: $daysDelayed
      year: $year
      season: $season
    ) {
      id
      latitude
      longitude
      addedAt
      address
      distance
      timeDistance
      lastWateringAt
      createdAt
      season
      waterings {
        id
        createdAt
      }
    }
  }
`;

export const TREE = gql`
  query Tree($id: ID!) {
    tree(id: $id) {
      id
      latitude
      longitude
      address
      addedAt
      distance
      timeDistance
      lastWateringAt
      nextTreeId
    }
  }
`;
