import { useSetRecoilState } from "recoil";

import { snackbarState } from "state/atoms";

const useSnack = () => {
  const setSnack = useSetRecoilState(snackbarState);

  const onSnackError = (message: string) => {
    setSnack({ type: "error", open: true, message });
  };
  const onSnackSuccess = (message: string) => {
    setSnack({ type: "success", open: true, message });
  };
  return {
    onSnackError,
    onSnackSuccess,
  };
};

export { useSnack };
