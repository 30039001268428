import React, { useCallback, useRef, useEffect, useMemo } from "react";
import { Container, Box, Typography, List } from "@material-ui/core";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import { LineLoading } from "components";
import { useHistory } from "react-router-dom";
// State
import { useSetRecoilState, useRecoilValue } from "recoil";
import { modalWaterState, queryTreesVars } from "state/atoms";
// UI
// Graphql and Services
import { useQuery } from "@apollo/react-hooks";
import {
  Tree,
  // TreeStat
} from "api/types";
import {
  TREES,
  // TREE_STATS
} from "api/queries";
// Components
// import HomeListSubHeader from "./components/HomeListSubHeader";
// Utils
import uniqBy from "lodash/uniqBy";
// Styles
import { useStyles } from "./components/HomePage.styles";
// Component
import HomePageTreeListItem from "./components/HomePageTreeListItem";

const HomePage: React.FC = () => {
  const setModalWaterState = useSetRecoilState(modalWaterState);
  const treesVars = useRecoilValue(queryTreesVars);
  const classes = useStyles();
  const observerRef = useRef<IntersectionObserver | null>(null);

  // const { data: treeStats } = useQuery<{
  //   treeStats: TreeStat[];
  // }>(TREE_STATS);

  const { loading, data, fetchMore, error } = useQuery<{
    trees: Tree[];
  }>(TREES, { fetchPolicy: "network-only", variables: treesVars });

  const history = useHistory();

  useEffect(() => {
    if (error?.graphQLErrors && error.graphQLErrors.length > 0) {
      const err = error.graphQLErrors[0];
      // @ts-ignore
      if (err?.status === 401) {
        history.push("/login");
      }
    }
  }, [error, history]);

  const lastTreeElRef = useCallback(
    (node: Element | null) => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
      observerRef.current = new IntersectionObserver(async (entries) => {
        const [entry] = entries;
        if (entry.isIntersecting && data?.trees && data?.trees.length) {
          try {
            await fetchMore({
              variables: { offset: data?.trees.length },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (
                  !fetchMoreResult ||
                  (fetchMoreResult?.trees &&
                    fetchMoreResult?.trees.length === 0)
                ) {
                  return prev;
                }
                return {
                  trees: uniqBy(
                    [...prev.trees, ...fetchMoreResult.trees],
                    "id"
                  ),
                };
              },
            });
          } catch (error) {
            console.log("error: ", error);
          }
        }
      });
      if (node) {
        observerRef.current.observe(node);
      }
    },
    [data, fetchMore]
  );

  const trees = useMemo(() => {
    if (data?.trees && data.trees.length > 0) {
      return data.trees;
    }
    return [];
  }, [data]);

  const renderContent = useCallback(() => {
    if (!trees.length) {
      return (
        <Box display="flex" m={3} justifyContent="center">
          <SentimentVeryDissatisfiedIcon style={{ fontSize: 40 }} />
          <Typography variant="h4" className={classes.emptyText}>
            No data available
          </Typography>
        </Box>
      );
    }
    return trees.map((tree) => (
      <HomePageTreeListItem
        key={tree.id}
        ref={lastTreeElRef}
        tree={tree}
        daysDelayed={treesVars.daysDelayed}
        onStartWatering={(id) => {
          setModalWaterState((p) => ({ ...p, id, open: true }));
        }}
      />
    ));
  }, [
    classes,
    trees,
    lastTreeElRef,
    treesVars.daysDelayed,
    setModalWaterState,
  ]);

  return (
    <>
      <LineLoading show={loading} />
      <Container className={classes.container} maxWidth="md">
        <Box boxShadow={1}>
          <List
          // subheader={
          //   <HomeListSubHeader treeStats={treeStats?.treeStats ?? []} />
          // }
          >
            {renderContent()}
          </List>
        </Box>
      </Container>
    </>
  );
};

export default HomePage;
