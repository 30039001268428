import React, { useState, useEffect } from "react";
import { useRecoilValue } from "recoil";
// Hooks
import { useSnack } from "hooks/useSnack";
// State
import { geolocationState } from "state/atoms";
// Graphql
import { ONCREATETREE } from "api/mutations";
import { TreeInput } from "api/types";
import { useMutation } from "@apollo/react-hooks";
// Util
import NewTreePageForm, {
  Viewport,
  TreePageFormValues,
} from "./components/NewTreePageForm";

const NewTreePage = () => {
  const { onSnackError, onSnackSuccess } = useSnack();

  const [viewport, setViewport] = useState<Viewport>({
    latitude: 0,
    longitude: 0,
    zoom: 16,
  });

  const geo = useRecoilValue(geolocationState);

  const [createTree, { loading }] = useMutation<{
    data: TreeInput;
  }>(ONCREATETREE);

  async function onSubmit(values: TreePageFormValues) {
    if (!values.geoValues) {
      onSnackError("Ops! Please fill a address");
      return;
    }
    const { geoValues, yearAdded } = values;
    try {
      await createTree({
        variables: {
          data: {
            latitude: geoValues.coordinates[1],
            longitude: geoValues.coordinates[0],
            address: geoValues.place,
            yearAdded,
          },
        },
      });
      onSnackSuccess("Success! Tree created!");
    } catch (error) {
      onSnackError("Ops! Something went wrong...");
    }
  }

  useEffect(() => {
    setViewport((p) => ({ ...p, ...geo }));
  }, [geo]);

  return (
    <NewTreePageForm
      viewport={viewport}
      setViewport={setViewport}
      loading={loading}
      onSubmit={onSubmit}
    />
  );
};

export default NewTreePage;
