import React from "react";

import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  CircularProgress,
} from "@material-ui/core";

interface ModalProps {
  open: boolean;
  title: string;
  loading: boolean;
  onBack(): void;
  onSuccess(): void;
}

const Modal: React.FC<ModalProps> = (props) => {
  const { open, title, loading, onBack, onSuccess } = props;
  return (
    <Dialog open={open} onClose={onBack}>
      <DialogTitle>{title}</DialogTitle>
      <DialogActions>
        <Button onClick={onBack}>BACK</Button>
        <Button color="primary" onClick={onSuccess}>
          YES
        </Button>
        {loading && <CircularProgress size={20} />}
      </DialogActions>
    </Dialog>
  );
};

export default Modal;
