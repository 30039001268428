import "mapbox-gl/dist/mapbox-gl.css";
import React from "react";
import ReactDOM from "react-dom";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import { RecoilRoot } from "recoil";
// Graphql
import { ApolloProvider } from "@apollo/react-hooks";
import { ApolloClient } from "api";
import theme from "./theme";
// Routes
import Routes from "./routes";

import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
  <ApolloProvider client={ApolloClient}>
    <ThemeProvider theme={theme}>
      <RecoilRoot>
        <CssBaseline />
        <Routes />
      </RecoilRoot>
    </ThemeProvider>
  </ApolloProvider>,
  document.getElementById("root")
);

serviceWorker.unregister();
