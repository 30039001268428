import React from "react";
import { ListItemText, ListItem, makeStyles, Theme } from "@material-ui/core";
import { Watering } from "api/types";
import { format } from "date-fns";

interface WateringsListItemProps {
  watering: Watering;
}

export const useStyles = makeStyles((theme: Theme) => {
  return {
    address: {
      fontWeight: 500,
    },
    date: {
      color: theme.palette.text.secondary,
    },
  };
});

const WateringsListItem = React.forwardRef<any, WateringsListItemProps>(
  ({ watering }, ref) => {
    const { tree, createdAt, id } = watering;
    const classes = useStyles();

    return (
      <ListItem divider ref={ref} key={id}>
        <ListItemText
          primary={<span className={classes.address}>{tree.address}</span>}
          secondary={
            <span className={classes.date}>
              {format(new Date(createdAt), "MM/dd/yyyy HH:mm")}
            </span>
          }
        />
      </ListItem>
    );
  }
);

WateringsListItem.displayName = "WateringsListItem";

export default WateringsListItem;
