import React, { useEffect, useState, useMemo, useCallback } from "react";
import MapGL, { Marker } from "react-map-gl";
import { useParams, useHistory } from "react-router-dom";
// State
import { useSetRecoilState, useRecoilValue } from "recoil";
import { snackbarState, modalWaterState, geolocationState } from "state/atoms";
// Graphql
import { useQuery, useMutation } from "@apollo/react-hooks";
import { ONDELETETREE } from "api/mutations";
import { TREE } from "api/queries";
import { Tree } from "api/types";
// UI
import DeleteIcon from "@material-ui/icons/Delete";
import NavigationIcon from "@material-ui/icons/Navigation";
import MarkerIcon from "@material-ui/icons/Room";
import {
  Button,
  Container,
  makeStyles,
  IconButton,
  Typography,
  Grid,
  Box,
  Theme,
} from "@material-ui/core";
// UI
import { LineLoading, Modal } from "components";
// Utils
import { getDateToNow } from "utils/date";
// Assets
import WazeImg from "./waze.png";

type DetailPageParams = {
  id: string;
};

const useClasses = makeStyles((theme: Theme) => {
  return {
    container: {
      marginTop: theme.spacing(3),
    },
    lastGridItem: {
      [theme.breakpoints.down("sm")]: {
        order: -1,
      },
    },
    buttonWazeImage: {
      height: 30,
    },
    buttonDelete: {
      position: "absolute",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  };
});

const DetailPage: React.FC = () => {
  const classes = useClasses();
  const { id } = useParams<DetailPageParams>();
  const history = useHistory();

  const [onDelete, { loading: deleteLoading }] = useMutation<{
    deleteTree: Tree;
  }>(ONDELETETREE);
  const { data, loading, refetch } = useQuery<{ tree: Tree }>(TREE, {
    fetchPolicy: "network-only",
    variables: {
      id,
    },
  });

  useEffect(() => {
    setInterval(() => {
      refetch();
    }, 60 * 1000);
  }, [refetch]);

  // State
  const [modalOpen, setModalOpen] = useState(false);
  const [vp, setVp] = useState({
    latitude: 0,
    longitude: 0,
    zoom: 16,
  });

  const { latitude, longitude } = useRecoilValue(geolocationState);
  const setModalState = useSetRecoilState(modalWaterState);
  const setSnackbarState = useSetRecoilState(snackbarState);

  useEffect(() => {
    if (data?.tree?.latitude && data?.tree?.longitude) {
      setModalState((p) => ({
        ...p,
        showNextId: true,
        id: data.tree.id,
      }));
      setVp((p) => ({
        ...p,
        latitude: data.tree.latitude,
        longitude: data.tree.longitude,
      }));
    }
    return () => {
      setModalState((p) => ({ ...p, showNextId: false }));
    };
  }, [data, setModalState]);

  const onRemove = useCallback(async () => {
    try {
      await onDelete({
        variables: {
          id,
        },
      });
      setModalOpen(false);
      history.push("/");
      setSnackbarState((p) => ({
        ...p,
        open: true,
        type: "success",
        message: "Removed",
      }));
    } catch (error) {
      setSnackbarState((p) => ({
        ...p,
        open: true,
        type: "error",
        message: "Ops! Something went wrong..",
      }));
    }
  }, [history, id, onDelete, setSnackbarState]);

  const onRouteGoogle = useCallback(() => {
    if (latitude && longitude) {
      window.location.href = `https://www.google.com/maps/dir/?api=1&origin=${latitude},${longitude}&destination=${vp.latitude},${vp.longitude}`;
    }
  }, [latitude, longitude, vp]);
  const onRouteWaze = useCallback(() => {
    if (vp.latitude && vp.longitude) {
      window.location.href = `https://www.waze.com/ul?ll=${vp.latitude},${vp.longitude}&navigate=yes&zoom=17`;
    }
  }, [vp]);

  const renderContent = useMemo(() => {
    if (data?.tree) {
      return (
        <>
          <MapGL
            {...vp}
            height="40vh"
            width="100%"
            mapStyle="mapbox://styles/mapbox/light-v9"
            mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
          >
            <Marker longitude={vp.longitude} latitude={vp.latitude}>
              <MarkerIcon />
            </Marker>
          </MapGL>
          <Container>
            <Grid container className={classes.container}>
              <Grid item xs={12} sm={6}>
                <Box display="flex" alignItems="center">
                  <Box>
                    <Typography variant="h6">Address:</Typography>
                    <Typography>{data.tree.address}</Typography>
                    <Typography variant="h6">Last Time Watered:</Typography>
                    <Typography>
                      {data.tree.lastWateringAt
                        ? getDateToNow(data.tree.lastWateringAt)
                        : "No data"}
                    </Typography>
                    <IconButton
                      className={classes.buttonDelete}
                      onClick={() => setModalOpen(true)}
                      aria-label="delete"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.lastGridItem}>
                <Box display="flex" alignItems="center" justifyContent="center">
                  <Button
                    onClick={onRouteGoogle}
                    size="large"
                    startIcon={<NavigationIcon fontSize="large" />}
                  >
                    Maps
                  </Button>
                  <Button
                    onClick={onRouteWaze}
                    size="large"
                    startIcon={
                      <img
                        src={WazeImg}
                        className={classes.buttonWazeImage}
                        alt="wazeicon"
                      />
                    }
                  >
                    Waze
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Container>
          <Modal
            open={modalOpen}
            onBack={() => setModalOpen(false)}
            loading={deleteLoading}
            title="Confirm Delete?"
            onSuccess={onRemove}
          />
        </>
      );
    }
    return null;
  }, [
    data,
    vp,
    classes,
    deleteLoading,
    modalOpen,
    onRemove,
    onRouteGoogle,
    onRouteWaze,
  ]);

  return (
    <>
      <LineLoading show={loading} />
      {renderContent}
    </>
  );
};

export default DetailPage;
