import config from "config";
import { MapboxResponse, factoryNewListMapbox } from "utils/factories";

type GetMapboxCoordinatesParams = {
  latitude: number;
  longitude: number;
  address: string;
};

const getMapboxCoordinates = async (params: GetMapboxCoordinatesParams) => {
  const { address, latitude, longitude } = params;
  const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${address}.json?proximity=${longitude},${latitude}&country=us&access_token=${config.MAPBOXTOKEN}`;
  const response = (await fetch(url, {
    method: "GET",
  }).then((r) => r.json())) as MapboxResponse;
  const features = response?.features ?? [];
  return factoryNewListMapbox(features);
};

export { getMapboxCoordinates };
