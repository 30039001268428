import React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import Template from "./Template/Template";
// Services
import StorageService from "services/Storage";

interface NewRouteProps extends RouteProps {
  component: React.FC<any>;
}

export default function ProtectedRoute({
  component: Component,
  ...rest
}: NewRouteProps) {
  const userToken = StorageService.getUserToken();
  // console.log("userToken: ", userToken);

  return (
    <Template>
      <Route
        render={(p) =>
          !userToken ? <Redirect to="/login" /> : <Component {...p} />
        }
        {...rest}
      />
    </Template>
  );
}
