import { createMuiTheme, colors } from "@material-ui/core";

export default createMuiTheme({
  palette: {
    primary: {
      main: "#124116",
    },
    secondary: {
      main: "#81c784",
    },
    // background: {
    //   default: "#6fbf73",
    // },
    success: {
      main: "#4caf50",
    },
    error: {
      main: colors.red[500],
      light: colors.red[200],
    },
  },
});
