import React, { useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
// UI
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Modal } from "components";
// State
import { useRecoilState } from "recoil";
import {
  snackbarState,
  modalWaterState,
  geolocationState,
  treesQueryVarState,
} from "state/atoms";
// Graphql
import { useMutation } from "@apollo/react-hooks";
import { ONWATER } from "api/mutations";
import { Watering } from "api/types";
// Parts
import TemplateHeader from "./components/TemplateHeader";
// Services
import Storage from "services/Storage";

interface TemplateProps {
  isLoginPage?: boolean;
}

const Template: React.FC<TemplateProps> = (props) => {
  const [snack, setSnack] = useRecoilState(snackbarState);
  const [mainQueryVars, setMainQueryVars] = useRecoilState(treesQueryVarState);
  const [modalState, setModalState] = useRecoilState(modalWaterState);
  const [location, setLocation] = useRecoilState(geolocationState);

  function onCloseModalWater() {
    setModalState((p) => ({ ...p, open: false }));
  }

  const [onWaterMutate, { loading }] = useMutation<{
    createWatering: Watering;
  }>(ONWATER, {
    // update: (cache, { data }) => {
    //   const response = cache.readQuery<{ trees: Tree[] }>({
    //     query: TREES,
    //     variables: homeQueryTreeVars,
    //   });
    //   if (response?.trees && data?.createWatering) {
    //     let { trees } = response;
    //     const { createWatering } = data;
    //     trees = trees.filter((tr) => tr.id !== createWatering.treeId);
    //     cache.writeQuery<{ trees: Tree[] }>({
    //       query: TREES,
    //       variables: homeQueryTreeVars,
    //       data: {
    //         trees,
    //       },
    //     });
    //   }
    // },
  });

  const history = useHistory();

  const getLocation = useCallback(() => {
    navigator.geolocation.getCurrentPosition(({ coords }) => {
      // console.log("coords: ", coords);
      setLocation({
        latitude: coords.latitude,
        longitude: coords.longitude,
      });
    });
  }, [setLocation]);

  const getDaysFromStorage = useCallback(() => {
    const days = Storage.getDaysDelayed();
    setMainQueryVars((p) => ({ ...p, daysDelayed: days }));
  }, [setMainQueryVars]);

  useEffect(() => {
    getDaysFromStorage();
  }, [getDaysFromStorage]);

  useEffect(() => {
    getLocation();
    setInterval(() => {
      getLocation();
    }, 60 * 1000);
  }, [getLocation]);

  const { children, isLoginPage } = props;

  async function onWater() {
    try {
      const { data } = await onWaterMutate({
        variables: {
          id: modalState.id,
          daysDelayed: mainQueryVars.daysDelayed,
          showNextTree: modalState.showNextId,
          ...(location.longitude && {
            ...location,
          }),
        },
      });
      setSnack({
        open: true,
        type: "success",
        message: "Watered!",
      });
      if (data?.createWatering?.tree?.nextTreeId) {
        history.push(`/detail/${data.createWatering.tree.nextTreeId}`);
      }
      onCloseModalWater();
    } catch (error) {
      onCloseModalWater();
      setSnack({
        open: true,
        type: "error",
        message: "Ops, some error occours",
      });
    }
  }

  function onCloseSnack() {
    setSnack((prev) => ({
      ...prev,
      open: false,
    }));
  }

  function renderSnack() {
    return (
      <Snackbar
        open={snack.open}
        autoHideDuration={1500}
        onClose={onCloseSnack}
      >
        <Alert
          variant="filled"
          severity={snack.type}
          onClose={onCloseSnack}
          color={snack.type}
        >
          {snack.message}
        </Alert>
      </Snackbar>
    );
  }

  if (isLoginPage) {
    return (
      <>
        {children}
        {renderSnack()}
      </>
    );
  }

  return (
    <>
      <TemplateHeader />
      {children}
      {renderSnack()}
      <Modal
        open={modalState.open}
        loading={loading}
        onBack={onCloseModalWater}
        onSuccess={onWater}
        title="Confirm Watering?"
      />
    </>
  );
};

export default Template;
