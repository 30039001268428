import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
// Template
import Template from "components/Template/Template";
// Pages
import ProtectedRoute from "./components/ProtectedRoute";
import LoginPage from "./pages/login/LoginPage";
import HomePage from "./pages/home/HomePage";
import DetailPage from "./pages/detail/DetailPage";
import NewTreePage from "./pages/newTree/NewTreePage";
import ReportsPage from "./pages/reports/ReportsPage";
import WateringsPage from "./pages/waterings/WateringsPage";

const Routes: React.FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/login">
          <Template isLoginPage>
            <LoginPage />
          </Template>
        </Route>
        <ProtectedRoute component={HomePage} exact path="/" />
        <ProtectedRoute component={NewTreePage} exact path="/new" />
        <ProtectedRoute component={DetailPage} exact path="/detail/:id" />
        <ProtectedRoute component={ReportsPage} exact path="/reports" />
        <ProtectedRoute
          component={WateringsPage}
          exact
          path="/reports/:userId"
        />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
