import { Color } from "@material-ui/lab";
import { atom, selector } from "recoil";

interface SnackProps {
  open: boolean;
  message: string;
  type: Color;
}

interface ModalMutateStateProps {
  open: boolean;
  id: string;
  showNextId?: boolean;
}

interface GeolocationProps {
  latitude: number;
  longitude: number;
}

export const SortText = {
  DISTANCE: "Closest",
  DISTANCE_DESC: "Further",
  WATERED: "Oldest",
  WATERED_DESC: "Most Recent",
};

export type SortTypes = keyof typeof SortText;

export type FilterOptions = "INTIME" | "DELAYED" | "ALL";

export const Seasons = {
  SPRING: "Spring",
  WINTER: "Winter",
  FALL: "Fall",
  SUMMER: "Summer",
  ALL: "All",
} as const;

export type SeasonTypes = keyof typeof Seasons;

interface TreesVariablesProps {
  offset: number;
  limit: number;
  filterBy: FilterOptions;
  orderBy: SortTypes;
  year: string | null;
  daysDelayed: number;
  season: SeasonTypes;
}

export const treesQueryVarState = atom<TreesVariablesProps>({
  key: "treesQueryVariablesState",
  default: {
    offset: 0,
    limit: 40,
    orderBy: "DISTANCE",
    filterBy: "DELAYED",
    year: "ALL",
    daysDelayed: 21,
    season: "ALL",
  },
});

export const queryTreesVars = selector({
  key: "treesVars",
  get: ({ get }) => {
    const { year, season, ...vars } = get(treesQueryVarState);
    const coords = get(geolocationState);
    // const coords = {
    //   latitude: 42.3796563,
    //   longitude: -71.0657849,
    // };
    return {
      year: year === "ALL" ? null : year,
      season: season === "ALL" ? null : season,
      ...vars,
      daysDelayed: 7,
      orderBy: "DISTANCE",
      filterBy: "DELAYED",
      ...coords,
    };
  },
});

export const snackbarState = atom<SnackProps>({
  key: "snackbarState",
  default: {
    open: false,
    message: "Teste",
    type: "error",
  },
});

export const modalWaterState = atom<ModalMutateStateProps>({
  key: "modalMutateState",
  default: {
    open: false,
    id: "",
    showNextId: false,
  },
});

export const geolocationState = atom<GeolocationProps>({
  key: "geolocationState",
  default: {
    latitude: 0,
    longitude: 0,
  },
});
