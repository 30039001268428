import { makeStyles } from "@material-ui/core";

export const useClasses = makeStyles(({ spacing }) => ({
  inputBoxes: {
    display: "flex",
    position: "absolute",
    zIndex: 2,
    left: "50%",
    transform: "translateX(-50%)",
    top: 50,
  },
  dateBoxFormControl: {
    minWidth: 150,
    marginLeft: spacing(2),
  },
  dateInput: {
    backgroundColor: "#fff",
  },
  autocompleteBox: {
    width: 300,
  },
  autocompleteInput: {
    backgroundColor: "#fff",
  },
  button: {
    width: 300,
  },
  buttonLoading: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));
