import React, { useState, useMemo } from "react";
import { useHistory } from "react-router-dom";
import {
  AppBar,
  Box,
  Button,
  Toolbar,
  IconButton,
  Drawer,
  makeStyles,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import ReceiptIcon from "@material-ui/icons/Receipt";
import ExitIcon from "@material-ui/icons/ExitToApp";
import ArrowBack from "@material-ui/icons/ArrowBack";
import AddIcon from "@material-ui/icons/Add";
// State
import { useSetRecoilState } from "recoil";
import { modalWaterState } from "state/atoms";
// Services
import StorageServices from "services/Storage";

const useStyles = makeStyles({
  drawer: {
    width: 200,
  },
  buttonWater: {
    color: "#fff",
    borderColor: "#fff",
  },
});

const TemplateHeader = () => {
  const setModalState = useSetRecoilState(modalWaterState);
  const classes = useStyles();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const history = useHistory();
  const { location } = history;

  const canGoBack = location.pathname !== "/";

  function onOpen() {
    setDrawerOpen(true);
  }
  function onClose() {
    setDrawerOpen(false);
  }

  function onLogout() {
    StorageServices.removeUserToken();
    setDrawerOpen(false);
    history.push("/login");
  }

  function onGoReportsPage() {
    setDrawerOpen(false);
    history.push("/reports");
  }

  function onGoNewPage() {
    setDrawerOpen(false);
    history.push("/new");
  }

  const isDetailPage = useMemo(() => location.pathname.includes("/detail"), [
    location,
  ]);

  function onWater() {
    setModalState((p) => ({ ...p, open: true }));
  }

  return (
    <>
      <Drawer anchor={"left"} onClose={onClose} open={drawerOpen}>
        <div className={classes.drawer} role="presentation">
          <List>
            <ListItem button onClick={onGoNewPage}>
              <ListItemIcon>
                <AddIcon />
              </ListItemIcon>
              <ListItemText>Add new Tree</ListItemText>
            </ListItem>
            <ListItem button onClick={onGoReportsPage}>
              <ListItemIcon>
                <ReceiptIcon />
              </ListItemIcon>
              <ListItemText>Reports</ListItemText>
            </ListItem>
            <Divider />
            <ListItem button onClick={onLogout}>
              <ListItemIcon>
                <ExitIcon />
              </ListItemIcon>
              <ListItemText>Logout</ListItemText>
            </ListItem>
          </List>
        </div>
      </Drawer>
      <AppBar position="static">
        <Toolbar>
          {canGoBack ? (
            <IconButton
              onClick={() => history.goBack()}
              edge="start"
              color="inherit"
              aria-label="back"
            >
              <ArrowBack />
            </IconButton>
          ) : (
            <IconButton
              onClick={onOpen}
              edge="start"
              color="inherit"
              aria-label="menu"
            >
              <MenuIcon />
            </IconButton>
          )}
          {isDetailPage && (
            <Box ml="auto">
              <Button
                onClick={onWater}
                className={classes.buttonWater}
                variant="outlined"
              >
                Water!
              </Button>
            </Box>
          )}
        </Toolbar>
      </AppBar>
    </>
  );
};

export default TemplateHeader;
