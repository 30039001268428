import React, { useEffect, useMemo, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import { DateRange } from "@material-ui/icons";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { formatDate, getDateFromParam } from "utils/date";
import { WateringsContent } from "./components/WateringsContent";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      marginTop: theme.spacing(4),
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    button: {
      marginTop: theme.spacing(2),
    },
    icon: {
      verticalAlign: "middle",
      marginRight: theme.spacing(1),
    },
  })
);

const UserWateringsPage: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const { userId } = useParams<{ userId: string }>();

  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(null);
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null);

  useEffect(() => {
    if (!userId) history.push("/reports");
  }, [userId, history]);

  const queryDates = useMemo(() => {
    const params = new URLSearchParams(location.search);
    const start = params.get("start");
    const end = params.get("end");
    if (start && end) {
      return { start, end };
    }
    return null;
  }, [location.search]);

  const handleSubmit = () => {
    if (selectedStartDate && selectedEndDate) {
      const startDate = formatDate(selectedStartDate);
      const endDate = formatDate(selectedEndDate);
      history.push(`/reports/${userId}?start=${startDate}&end=${endDate}`);
    }
  };

  useEffect(() => {
    if (queryDates?.start && queryDates.end) {
      setSelectedStartDate(getDateFromParam(queryDates.start));
      setSelectedEndDate(getDateFromParam(queryDates.end));
    }
  }, [queryDates]);

  return (
    <Container className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <DateRange className={classes.icon} />
              <Typography variant="h5" gutterBottom>
                Select Watering Date Range
              </Typography>
            </Box>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container spacing={3} justify="center">
                <Grid item>
                  <KeyboardDatePicker
                    margin="normal"
                    id="start-date-picker"
                    label="Start Date"
                    format="MM/dd/yyyy"
                    value={selectedStartDate}
                    onChange={setSelectedStartDate}
                    maxDate={selectedEndDate}
                    KeyboardButtonProps={{
                      "aria-label": "change start date",
                    }}
                  />
                </Grid>
                <Grid item>
                  <KeyboardDatePicker
                    margin="normal"
                    id="end-date-picker"
                    label="End Date"
                    format="MM/dd/yyyy"
                    minDate={selectedStartDate}
                    value={selectedEndDate}
                    onChange={setSelectedEndDate}
                    KeyboardButtonProps={{
                      "aria-label": "change end date",
                    }}
                  />
                </Grid>
              </Grid>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={handleSubmit}
                disabled={!selectedStartDate || !selectedEndDate}
              >
                Submit
              </Button>
            </MuiPickersUtilsProvider>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <WateringsContent queryDates={queryDates} userId={userId} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default UserWateringsPage;
