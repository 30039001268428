import gql from "graphql-tag";

export const SIGNIN = gql`
  mutation SignIn($username: String!, $password: String!) {
    signIn(username: $username, password: $password) {
      token
      user {
        id
        name
        username
        email
      }
    }
  }
`;

export const ONWATER = gql`
  mutation Water(
    $id: ID!
    $showNextTree: Boolean
    $daysDelayed: Int
    $latitude: Float
    $longitude: Float
  ) {
    createWatering(
      id: $id
      showNextTree: $showNextTree
      latitude: $latitude
      longitude: $longitude
      daysDelayed: $daysDelayed
    ) {
      id
      treeId
      tree {
        id
        address
        latitude
        longitude
        lastWateringAt
        nextTreeId
      }
      createdAt
    }
  }
`;

export const ONDELETETREE = gql`
  mutation DeleteTree($id: ID!) {
    deleteTree(id: $id) {
      id
    }
  }
`;

export const ONCREATETREE = gql`
  mutation CreateTree($data: TreeInput!) {
    createTree(data: $data) {
      latitude
      longitude
      createdAt
      yearAdded
      address
    }
  }
`;
