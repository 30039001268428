import React, { useCallback, useState, useEffect } from "react";
import {
  Button,
  CircularProgress,
  Box,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@material-ui/core";
import MarkerIcon from "@material-ui/icons/Room";
import { Autocomplete } from "@material-ui/lab";
import MapGL, { Marker } from "react-map-gl";
import { useRecoilValue } from "recoil";
// Hooks
import useDebounceValue from "hooks/useDebounce";
// State
import { geolocationState } from "state/atoms";
// Util
import { MapBoxListOption } from "utils/factories";
import { generateYearArray } from "utils/date";
// Services
import { getMapboxCoordinates } from "services/GeoServices";

import { useClasses } from "./NewTreePageForm.styles";

export type Viewport = {
  latitude: number;
  longitude: number;
  zoom: number;
};

export type TreePageFormValues = {
  geoValues?: MapBoxListOption;
  yearAdded?: string;
};

export type TreePageSubmit = (args: TreePageFormValues) => void;

type NewTreePageFormProps = {
  viewport: Viewport;
  setViewport: React.Dispatch<React.SetStateAction<Viewport>>;
  loading: boolean;
  onSubmit: TreePageSubmit;
};

const NewTreePageForm = ({
  loading,
  onSubmit,
  viewport,
  setViewport,
}: NewTreePageFormProps) => {
  const [debounceValue, inputValue, setInputValue] = useDebounceValue("", 1000);
  const [searchValue, setSearchValue] = useState<MapBoxListOption>();
  const [treeYear, setTreeDate] = useState("");
  const [options, setOptions] = useState<MapBoxListOption[]>([]);
  const geo = useRecoilValue(geolocationState);

  const yearOptions = generateYearArray();

  const getCoordinates = useCallback(
    async (address: string) => {
      const features = await getMapboxCoordinates({
        address,
        ...geo,
      });
      setOptions(features);
    },
    [geo]
  );

  const onInputChange = (
    _: React.ChangeEvent<Record<string, unknown>>,
    value: string
  ) => {
    setInputValue(value);
  };

  const handleSelectYear = (event: React.ChangeEvent<{ value: unknown }>) => {
    setTreeDate(event.target.value as string);
  };

  useEffect(() => {
    if (debounceValue.length > 3) {
      getCoordinates(debounceValue);
    }
  }, [debounceValue, getCoordinates]);

  useEffect(() => {
    if (inputValue === "") {
      setOptions([]);
    }
  }, [inputValue]);

  const classes = useClasses();
  return (
    <Box position="relative">
      <Box className={classes.inputBoxes}>
        <Autocomplete
          classes={{
            root: classes.autocompleteBox,
            inputRoot: classes.autocompleteInput,
          }}
          filterOptions={(x) => x}
          getOptionLabel={(x) => x.place}
          options={options}
          autoComplete
          freeSolo
          includeInputInList
          filterSelectedOptions
          value={searchValue}
          onChange={(_, newValue) => {
            setSearchValue(newValue as MapBoxListOption);
            if (typeof newValue === "object" && newValue?.coordinates) {
              setViewport((p) => ({
                ...p,
                latitude: newValue.coordinates[1],
                longitude: newValue.coordinates[0],
              }));
            }
          }}
          onInputChange={onInputChange}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Add a location"
              variant="filled"
              fullWidth
            />
          )}
        />
        <FormControl
          classes={{}}
          variant="filled"
          className={classes.dateBoxFormControl}
        >
          <InputLabel id="select-tree-year">Tree Year</InputLabel>
          <Select
            classes={{
              filled: classes.dateInput,
            }}
            labelId="select-tree-year"
            value={treeYear}
            onChange={handleSelectYear}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {yearOptions.map((year) => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <MapGL
        {...viewport}
        clickRadius={2}
        height="60vh"
        width="100%"
        mapStyle="mapbox://styles/mapbox/light-v9"
        onViewportChange={(vp) => setViewport(vp)}
        mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
      >
        <Marker longitude={viewport.longitude} latitude={viewport.latitude}>
          <MarkerIcon />
        </Marker>
      </MapGL>
      <Box
        position="relative"
        justifyContent="center"
        alignItems="center"
        display="flex"
        mt={4}
      >
        <Button
          variant="contained"
          color="primary"
          size="large"
          className={classes.button}
          disabled={loading}
          onClick={() =>
            onSubmit({
              geoValues: searchValue,
              yearAdded: treeYear,
            })
          }
        >
          Create
        </Button>
        {loading && (
          <CircularProgress size={28} className={classes.buttonLoading} />
        )}
      </Box>
    </Box>
  );
};

export default NewTreePageForm;
