import React, { useEffect } from "react";
import {
  Button,
  Grid,
  Card,
  CardContent,
  CircularProgress,
  Theme,
  makeStyles,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useSetRecoilState } from "recoil";
import { snackbarState } from "state/atoms";
// UI
import { Input } from "components";
// Graphql and Services
import { useMutation } from "@apollo/react-hooks";
import { SIGNIN } from "api/mutations";
import { UserSignIn } from "api/types";
import StorageServices from "services/Storage";

type FormValues = {
  username: string;
  password: string;
};

const loginValidationSchema = Yup.object().shape({
  username: Yup.string().required("Required"),
  password: Yup.string().required("Required"),
});

const useStyles = makeStyles((theme: Theme) => {
  return {
    gridContainer: {
      minHeight: "100vh",
    },
    cardContent: {
      display: "flex",
      flexDirection: "column",
    },
    buttonWrapper: {
      marginTop: theme.spacing(2),
      position: "relative",
    },
    buttonLoading: {
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
  };
});

const HomePage: React.FC = () => {
  const setSnack = useSetRecoilState(snackbarState);
  const classes = useStyles();
  const history = useHistory();

  const [signin] = useMutation<{
    signIn: UserSignIn;
  }>(SIGNIN);

  useEffect(() => {
    const userToken = StorageServices.getUserToken();
    if (userToken) {
      history.push("/");
    }
  }, [history]);

  const onSubmit = async (values: FormValues) => {
    try {
      const { data } = await signin({
        variables: values,
      });
      if (data?.signIn?.token) {
        StorageServices.addUserToken(data.signIn.token);
        history.push("/");
        setSnack({
          message: "Success",
          open: true,
          type: "success",
        });
      }
    } catch (err) {
      const error = err as any;
      let message = "Ops! Something went wrong..";
      if (error?.graphQLErrors.length > 0) {
        message = error.graphQLErrors[0].message;
      }
      setSnack({
        message,
        open: true,
        type: "error",
      });
    }
  };
  return (
    <Grid
      container
      alignItems="center"
      justify="center"
      className={classes.gridContainer}
    >
      <Grid item xs={10} sm={4}>
        <Formik
          initialValues={{ username: "", password: "" }}
          onSubmit={onSubmit}
          validationSchema={loginValidationSchema}
        >
          {({ isSubmitting, isValid }) => {
            return (
              <Form autoComplete="off">
                <Card>
                  <CardContent className={classes.cardContent}>
                    <Input label="Username" name="username" fullWidth />
                    <Input
                      label="Password"
                      name="password"
                      type="password"
                      fullWidth
                      margin="normal"
                    />
                    <div className={classes.buttonWrapper}>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={!isValid || isSubmitting}
                        type="submit"
                        fullWidth
                      >
                        Enter
                      </Button>
                      {isSubmitting && (
                        <CircularProgress
                          size={24}
                          className={classes.buttonLoading}
                        />
                      )}
                    </div>
                  </CardContent>
                </Card>
              </Form>
            );
          }}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default HomePage;
