export type MapboxResponse = {
  attribution: string;
  query: string[];
  type: string;
  features: {
    center: number[];
    context: {
      id: string;
      text: string;
      wikidata?: string;
      short_code?: string;
    }[];
    geometry: {
      type: string;
      coordinates: number[];
    };
    id: string;
    place_name: string;
    place_type: string[];
    properties: {
      accuracy: string;
    };
    relevance: number;
    text: string;
    type: string;
  }[];
};

export type MapBoxListOption = {
  id: string;
  place: string;
  coordinates: number[];
};

export const factoryNewListMapbox = (
  features: MapboxResponse["features"]
): MapBoxListOption[] => {
  return features.map((fea) => ({
    id: fea.id,
    place: fea.place_name,
    coordinates: fea.center,
  }));
};
