enum STORAGE_KEYS {
  USER_TOKEN = "tree@user_token",
  DAYS_DELAYED = "tree@days_delayed",
}

function getUserToken(): string {
  return localStorage.getItem(STORAGE_KEYS.USER_TOKEN) || "";
}
function removeUserToken(): void {
  return localStorage.removeItem(STORAGE_KEYS.USER_TOKEN);
}
function addUserToken(token: string): void {
  return localStorage.setItem(STORAGE_KEYS.USER_TOKEN, token);
}

function setDaysDelayed(days: number): void {
  localStorage.setItem(STORAGE_KEYS.DAYS_DELAYED, String(days));
}

function getDaysDelayed() {
  const days = localStorage.getItem(STORAGE_KEYS.DAYS_DELAYED);
  return days ? Number(days) : 21;
}

export default {
  getUserToken,
  removeUserToken,
  addUserToken,
  setDaysDelayed,
  getDaysDelayed,
};
