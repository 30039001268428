import React from "react";
import { TextField, TextFieldProps } from "@material-ui/core";
import { useField } from "formik";

interface InputProps {
  name: string;
}

const Input: React.FC<InputProps & TextFieldProps> = (props) => {
  const { name } = props;
  const [field, meta] = useField(name);
  const error = meta.touched && meta.error;

  return (
    <TextField {...field} {...props} error={!!error} helperText={meta.error} />
  );
};

export default Input;
