import React from "react";
import { LinearProgress } from "@material-ui/core";

interface LoadingProps {
  show: boolean;
}

const Loading: React.FC<LoadingProps> = (props) => {
  const { show } = props;
  if (show) {
    return <LinearProgress color="secondary" />;
  }
  return null;
};
export default Loading;
